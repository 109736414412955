import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Grid, Tab, Paper, Typography, Alert, CircularProgress } from '@mui/material';
import ReservationDetails from '../components/order/ReservationDetails';
import AcceptTerms from '../components/order/AcceptTerms';
import CheckoutForm from '../components/order/CheckoutForm';
import ReviewOrder from '../components/order/ReviewOrder';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, setTabValue, updateDealData, updateCompanyData, handleWaitingListSignUp } from '../actions/order';
import api from '../axiosInstance';

const OrderForm = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.order.formData);
  const tabValue = useSelector((state) => state.order.tabValue);
  const orderId = useSelector((state) => state.order.orderId);
  const error = useSelector((state) => state.order.error);
  const isValueValid = useSelector((state) => state.order.isValueValid);
  const waitingListData = useSelector((state) => state.order.waitingListData);

  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);  // Track API errors
  const totalTabs = 4;
  const { dealId } = useParams();
  
  const requiredFields = {
    '1': ['reservation_amount'],
    '2': ['term_sheet_acceptance_1'],
    '3': ['term_sheet_acceptance_2', 'term_sheet_acceptance_3', 'term_sheet_acceptance_4']
  };

  const isFormValid = (currentTab) => {
    const fields = requiredFields[currentTab] || [];
    const fieldsSet = fields.every((field) => formData[field]);

    return fieldsSet && isValueValid;
  };

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const response = await api.get(`/api/products/deals/id/${dealId}/`);
        dispatch(updateDealData(response.data));
        fetchCompany(response.data.company);
      } catch (error) {
        console.error('Error fetching deal:', error);
        setApiError('Failed to load deal data. Please try again later.');
        setIsLoading(false);
      }
    };

    const fetchCompany = async (companyId) => {
      try {
        const response = await api.get(`/api/products/companies/${companyId}/`);
        dispatch(updateCompanyData(response.data));
        setIsLoading(false); // Stop loading once both deal and company data are fetched
      } catch (error) {
        console.error('Error fetching company:', error);
        setApiError('Failed to load company data. Please try again later.');
        setIsLoading(false);
      }
    };

    if (dealId) {
      fetchDeal();
    } else {
      setApiError('Invalid deal ID.');  // Handle if dealId is not available
      setIsLoading(false);
    }

  }, [dealId, dispatch]);


  const handleOrderPlacement = () => {
    if (isFormValid(tabValue)) {
      // Step 1: Calculate new formData based on waitingListData
      const updatedFormData = { ...formData };
  
      if (waitingListData) {
        // Subtract waiting list reservation amount, shares, and final amount from formData
        updatedFormData.reservation_amount -= waitingListData.reservation_amount;
        updatedFormData.equivalent_number_of_shares -= parseFloat(waitingListData.equivalent_number_of_shares);
        updatedFormData.final_reservation_amount -= waitingListData.final_reservation_amount;
      }
  
      // Step 2: Dispatch createOrder with updated formData
      dispatch(createOrder(dealId, updatedFormData, tabValue));
  
      // Step 3: After the order is created, dispatch handleWaitingListSignUp with the waitingListData
      if (waitingListData) {
        dispatch(handleWaitingListSignUp(dealId, waitingListData));
      }
  
      // Reset validation error
      setValidationError('');
    } else {
      setValidationError('Please complete all required fields before proceeding.');
    }
  };
  

  const handleNext = () => {
    if (isFormValid(tabValue)) {
      const nextTab = (parseInt(tabValue) + 1).toString();
      dispatch(setTabValue(nextTab));
      setValidationError('');
    } else {
      setValidationError('Please complete all required fields before proceeding.');
    }
  };

  const handleBack = () => {
    const prevTab = (parseInt(tabValue) - 1).toString();
    dispatch(setTabValue(prevTab));
    setValidationError('');
  };

  const renderButtons = () => {
    const isErrorPresent = !!error;
    const isNextDisabled = isErrorPresent || !isFormValid(tabValue);

    if (tabValue === '1') {
      return (
        <Button variant="contained" color="primary" onClick={handleNext} disabled={isNextDisabled}>
          Next
        </Button>
      );
    } else if (tabValue === (totalTabs - 1).toString()) {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="secondary" onClick={handleOrderPlacement}>
            Proceed to Payment
          </Button>
        </>
      );
    } else if (tabValue === totalTabs.toString()) {
      return <></>;
    } else {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </>
      );
    }
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (apiError) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <Alert severity="error">{apiError}</Alert>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} lg={10} display="flex" padding="40px">
      <Box component={Paper} style={{ width: '100%', padding: '60px', marginTop: '60px' }}>
        {validationError && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            {validationError}
          </Alert>
        )}

        <form>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList aria-label="order form tabs">
                <Tab label="Reservation Details" value="1" />
                <Tab label="Accept Terms" value="2" />
                <Tab label="Review" value="3" />
                <Tab label="Payment" value="4" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <ReservationDetails formData={formData}/>
            </TabPanel>

            <TabPanel value="2">
              <AcceptTerms formData={formData} />
            </TabPanel>

            <TabPanel value="3">
              <ReviewOrder formData={formData} />
            </TabPanel>

            <TabPanel value="4" key={`checkout-${tabValue}`}>
              <CheckoutForm formData={formData} orderId={orderId} />
            </TabPanel>
          </TabContext>
        </form>

        <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'center', alignItems: 'center' }}>
          {renderButtons()}

          {error && (
            <Typography variant="body1" color="error" sx={{ mt: 2 }}>
   
                {error}
            
            </Typography>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default OrderForm;
