import React, { useEffect, useState } from 'react';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, Paper, FormControlLabel, Checkbox, Tooltip, IconButton, Alert
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrderData, updateWaitingListData } from '../../actions/order';
import { ACCREDITED_STATUS_CHOICES, LIQUID_WORTH_CHOICES } from '../profiles/constants';

const formatAsCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

const getAccreditedStatusDescription = (status) => {
  const statusItem = ACCREDITED_STATUS_CHOICES.find(([key]) => key === status);
  return statusItem ? statusItem[1] : 'Unknown status';
};

const getLiquidWorthDescription = (worth) => {
  const worthItem = LIQUID_WORTH_CHOICES.find(([key]) => key === worth);
  return worthItem ? worthItem[1] : 'Unknown worth';
};

const OrderReviewSection = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.order.formData);
  const dealData = useSelector((state) => state.order.dealData);
  const profileData = useSelector((state) => state.profile);

  // Track whether the waiting list data has been dispatched already
  const [waitingListData, setWaitingListData] = useState(null);

  const handleChange = (event) => {
    const { name, checked } = event.target;

    const updatedFormData = {
      ...formData,
      [name]: checked,
    };

    dispatch(updateOrderData(updatedFormData));
  };

  const hasExcessShares = formData.equivalent_number_of_shares > dealData?.available_shares;

  const maxShares = dealData?.available_shares || 0;
  const availableAmount = maxShares * formData.share_price;
  const excessAmount = formData.reservation_amount - availableAmount;

  // Calculate waiting list details, only when needed
  useEffect(() => {
    if (hasExcessShares && !waitingListData && formData && dealData) {
      const calculatedWaitingListData = {
        reservation_amount: excessAmount,
        equivalent_number_of_shares: (excessAmount / formData.share_price).toFixed(2),
        final_reservation_amount: excessAmount,
      };

      // Set the waitingListData locally
      setWaitingListData(calculatedWaitingListData);

      // Dispatch the waiting list data only once to Redux
      dispatch(updateWaitingListData(calculatedWaitingListData));
    }
  }, [formData, dealData, excessAmount, hasExcessShares, waitingListData, dispatch]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px', justifyContent: 'center' }}>
      <Typography variant="h6" sx={{ padding: '20px', textAlign: 'center' }}>
        Please review your order & waiting list placement and click the button below to proceed to payment
      </Typography>

      {/* Display tables side-by-side if there is an excess */}
      <Box sx={{ display: 'flex', flexDirection: hasExcessShares ? 'row' : 'column', gap: 3 }}>
        {/* Table for immediate order */}
        <TableContainer component={Paper} sx={{ flex: 1 }}>
          <Typography variant="subtitle1" sx={{ padding: 2, fontWeight: 'bold' }}>
            Your Order
          </Typography>
          <Alert severity="success" sx={{ margin: 2 }}>
            This is how much you will buy today
          </Alert>
          <Table>
            <TableHead>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Reservation Amount</TableCell>
                <TableCell>{formatAsCurrency(Math.min(formData.reservation_amount, availableAmount))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Share Price (excl. Sales Fee)</TableCell>
                <TableCell>{formatAsCurrency(formData.share_price)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Final Reservation Amount (excl. Sales Fee)</TableCell>
                <TableCell>{formatAsCurrency(Math.min(formData.final_reservation_amount, availableAmount))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Equivalent Number of Shares</TableCell>
                <TableCell>{Math.min(formData.equivalent_number_of_shares, maxShares)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {hasExcessShares && waitingListData && (
          <TableContainer component={Paper} sx={{ backgroundColor: '#f7f7f7', flex: 1 }}>
            <Typography variant="subtitle1" sx={{ padding: 2, fontWeight: 'bold' }}>
              Waiting List Placement
            </Typography>
            <Alert severity="warning" sx={{ margin: 2 }}>
              This is the amount placed on the waiting list to be purchased once we secure more shares (very soon!)
            </Alert>
            <Table>
              <TableHead>
                <TableRow></TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Reservation Amount</TableCell>
                  <TableCell>{formatAsCurrency(waitingListData.reservation_amount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Share Price (excl. Sales Fee)</TableCell>
                  <TableCell>{formatAsCurrency(formData.share_price)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Final Reservation Amount (excl. Sales Fee)</TableCell>
                  <TableCell>{formatAsCurrency(waitingListData.final_reservation_amount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Equivalent Number of Shares</TableCell>
                  <TableCell>{waitingListData.equivalent_number_of_shares}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {/* Agreement Checkboxes */}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <FormControlLabel
          control={
            <Checkbox
              boolean={formData.term_sheet_acceptance_2}
              onChange={handleChange}
              name="term_sheet_acceptance_2"
            />
          }
          label={<Typography component="span">I have read and accept the Terms and Conditions of EquitiBridge<Typography component="span" color="error" sx={{ ml: 0.5 }}>*</Typography></Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              boolean={formData.term_sheet_acceptance_3}
              onChange={handleChange}
              name="term_sheet_acceptance_3"
            />
          }
          label={<Typography component="span">I understand that by proceeding I opt out of regulation<Typography component="span" color="error" sx={{ ml: 0.5 }}>*</Typography></Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              boolean={formData.term_sheet_acceptance_4}
              onChange={handleChange}
              name="term_sheet_acceptance_4"
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography component="span">
                I can confirm that my financial situation hasn't changed
                <Typography component="span" color="error" sx={{ ml: 0.5 }}>*</Typography>
              </Typography>
              <Tooltip
                title={
                  <Box sx={{ padding: '10px' }}>
                    <Typography variant="body2">Please ensure the following fields are still accurate:</Typography>
                    <ul>
                      {profileData.accredited_investor_status && (
                        <li>Accredited Investor Status: {getAccreditedStatusDescription(profileData.accredited_investor_status)}</li>
                      )}
                      {profileData.liquid_worth && (
                        <li>Liquid Worth: {getLiquidWorthDescription(profileData.liquid_worth)}</li>
                      )}
                      {(!profileData.accredited_investor_status && !profileData.liquid_worth) && (
                        <li>No profile data available.</li>
                      )}
                    </ul>
                    <Typography variant="body2">You can update your data in My Account section.</Typography>
                  </Box>
                }
                arrow
                placement="right"
              >
                <IconButton sx={{ padding: '5px', marginLeft: '5px' }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default OrderReviewSection;
