
// Auth Dispatch States

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';

// Profile Dispatch States

export const LOAD_INVESTOR_PROFILE_SUCCESS = 'LOAD_INVESTOR_PROFILE_SUCCESS';
export const LOAD_INVESTOR_PROFILE_FAIL = 'LOAD_INVESTOR_PROFILE_FAIL';
export const UPDATE_INVESTOR_PROFILE_SUCCESS = 'UPDATE_INVESTOR_PROFILE_SUCCESS';
export const UPDATE_INVESTOR_PROFILE_FAIL = 'UPDATE_INVESTOR_PROFILE_FAIL';


// Banking States

export const LOAD_BANKING_PROFILES_SUCCESS = 'LOAD_BANKING_PROFILES_SUCCESS';
export const LOAD_BANKING_PROFILES_FAIL = 'LOAD_BANKING_PROFILES_FAIL';
export const UPDATE_BANKING_PROFILE_SUCCESS = 'UPDATE_BANKING_PROFILE_SUCCESS';
export const UPDATE_BANKING_PROFILE_FAIL = 'UPDATE_BANKING_PROFILE_FAIL';
export const CREATE_BANKING_PROFILE_SUCCESS = 'CREATE_BANKING_PROFILE_SUCCESS';
export const CREATE_BANKING_PROFILE_FAIL = 'CREATE_BANKING_PROFILE_FAIL';


// Order Types

export const UPDATE_ORDER_DATA = 'UPDATE_ORDER_DATA';
export const RESET_ORDER_DATA = 'RESET_ORDER_DATA';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const SET_TAB_VALUE = 'SET_TAB_VALUE';




export const SET_ORDER_ERROR = 'SET_ORDER_ERROR';
export const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR';

export const SET_FORM_VALIDITY = 'SET_FORM_VALIDITY';

// action types
export const UPDATE_DEAL_DATA = 'UPDATE_DEAL_DATA';
export const UPDATE_COMPANY_DATA = 'UPDATE_COMPANY_DATA';

export const PLACE_WAITING_LIST_ORDER_REQUEST = "PLACE_WAITING_LIST_ORDER_REQUEST";
export const PLACE_WAITING_LIST_ORDER_SUCCESS = "PLACE_WAITING_LIST_ORDER_SUCCESS";
export const PLACE_WAITING_LIST_ORDER_FAILURE = "PLACE_WAITING_LIST_ORDER_FAILURE";

export const UPDATE_WAITING_LIST_DATA = 'UPDATE_WAITING_LIST_DATA';