import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Tab, Box, Paper, Alert } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import api from '../axiosInstance';
import CSRFToken from './general/CSRFToken';
import { useNavigate } from 'react-router-dom';
import NavButtons from './helpers/NavButtons';
import ReservationDetails from './order/ReservationDetails';
import ReviewOrder from './order/ReviewOrder';
import Loading from './general/Loading';

const WaitingListForm = () => {
    const { dealId } = useParams();
    const [deal, setDeal] = useState(null);
    const [formData, setFormData] = useState({
        reservation_amount: 0,
        share_price: 0,
        equivalent_number_of_shares: 0,
        final_reservation_amount: 0,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const [tabValue, setTabValue] = useState('1');
    const navigate = useNavigate();
    const totalTabs = 2;

    useEffect(() => {
        const fetchDeal = async () => {
            try {
                const response = await api.get(`/api/products/deals/id/${dealId}/`);
                setDeal(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error.message);
            }
        };
        fetchDeal();
    }, [dealId]);

    useEffect(() => {
        if (deal) {
            const initialFormData = {
                reservation_amount: deal.minimum_investment_size,
                share_price: deal.implied_share_price,
                equivalent_number_of_shares: Math.round((0.95 * deal.minimum_investment_size) / deal.implied_share_price),
                final_reservation_amount: 0.95 * deal.minimum_investment_size,
            };
            setFormData(initialFormData);

            const fetchCompany = async () => {
                try {
                    const response = await api.get(`/api/products/companies/${deal.company}/`);
                    setCompany(response.data);
                } catch (error) {
                    console.error(error.message);
                }
            };

            fetchCompany().finally(() => setLoading(false)); // Set loading to false here
        }
    }, [deal, setFormData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newFormData = { ...formData, [name]: value };

        if (name === 'reservation_amount') {
            newFormData = {
                ...newFormData,
                equivalent_number_of_shares: Math.round((0.95 * value) / formData.share_price),
                final_reservation_amount: 0.95 * value,
            };
        }

        setFormData(newFormData);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (loading || !deal || !company) {
        return <Loading />;
    }

    return (
        <Grid container justifyContent="center" style={{ maxWidth: '80%', margin: '0 auto', paddingTop: '40px', paddingBottom: '40px' }}>
            <Box component={Paper} style={{ width: '100%', padding: '60px', marginTop: '60px' }}>
                <Alert severity="warning">
                The deal you are interested in is currently oversubscribed. Please submit below your info and you will be placed on a waiting list.
                </Alert>
                <form>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="order form tabs" centered>
                                <Tab label="Reservation Details" value="1" />
                                <Tab label="Review and Submit" value="2" />
                            </TabList>
                        </Box>
                        <CSRFToken />

                        <TabPanel value="1">
                            <Grid container spacing={2} justifyContent="space-around" style={{ marginTop: '30px' }}>
                                <ReservationDetails formData={formData} setFormData={setFormData} handleChange={handleChange} deal={deal} company={company} />
                            </Grid>
                            <Grid container justifyContent="center">
                                <NavButtons
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                    deal={deal}
                                    formData={formData}
                                    totalTabs={totalTabs}
                                    setIsSubmitted={setIsSubmitted}
                                />
                            </Grid>
                        </TabPanel>

                        <TabPanel value="2">
                            <Grid container justifyContent="center">
                                <ReviewOrder formData={formData} handleChange={handleChange} />
                            </Grid>
                            <Grid container justifyContent="center">
                                <NavButtons
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                    deal={deal}
                                    formData={formData}
                                    totalTabs={totalTabs}
                                    setIsSubmitted={setIsSubmitted}
                                />
                            </Grid>
                        </TabPanel>
                    </TabContext>

                    {isSubmitted && (
                        <Box mt={4}>
                            <Alert severity="success">
                                Your Order has been placed on a waiting list. You will be notified by email if new shares become available.
                            </Alert>
                            <Button variant="contained" color="primary" onClick={() => navigate('/portfolio')}>
                                Go to Your Portfolio
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => navigate('/investments')}>
                                Invest More
                            </Button>
                        </Box>
                    )}
                </form>
            </Box>
        </Grid>
    );
};

export default WaitingListForm;
