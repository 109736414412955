import { 
    UPDATE_ORDER_DATA, 
    SET_FORM_VALIDITY, 
    PLACE_ORDER_REQUEST, 
    PLACE_ORDER_SUCCESS, 
    PLACE_ORDER_FAILURE, 
    SET_TAB_VALUE, 
    CLEAR_ORDER_ERROR, 
    UPDATE_DEAL_DATA, 
    UPDATE_COMPANY_DATA ,
    PLACE_WAITING_LIST_ORDER_FAILURE,
    PLACE_WAITING_LIST_ORDER_REQUEST,
    PLACE_WAITING_LIST_ORDER_SUCCESS,
    UPDATE_WAITING_LIST_DATA
} from "./types";
import Cookies from "js-cookie";
import api from '../axiosInstance';

// Action creator to set form validity
export const setValueValidity = (isValid) => ({
    type: SET_FORM_VALIDITY,
    payload: isValid,
});

// Action to update the order data
export const updateOrderData = (formData) => {
    return (dispatch) => {
        // Check if the number of shares exceeds available shares
        dispatch({
            type: UPDATE_ORDER_DATA,
            payload: formData,
        });
    };
};

// Action to clear the error message
export const clearOrderError = () => {
    return {
        type: CLEAR_ORDER_ERROR,
    };
};

// Action to update deal data
export const updateDealData = (dealData) => {
    return {
        type: UPDATE_DEAL_DATA,
        payload: dealData, // This will be the deal data you fetched
    };
};

// Action to update company data
export const updateCompanyData = (companyData) => {
    return {
        type: UPDATE_COMPANY_DATA,
        payload: companyData, // This will be the company data you fetched
    };
};

export const updateWaitingListData = (waitingListData) => {
    return {
      type: UPDATE_WAITING_LIST_DATA,
      payload: waitingListData,
    };
  };

// Action to create an order
export const createOrder = (dealId, formData, tabValue) => {
    return async (dispatch) => {
        dispatch({ type: PLACE_ORDER_REQUEST });

        const orderData = {
            deal: dealId,
            reservation_amount: formData.reservation_amount,
            equivalent_number_of_shares: formData.equivalent_number_of_shares,
            final_reservation_amount: formData.final_reservation_amount,
            status: 1, // Status will be updated by backend
        };

        try {
            const orderResponse = await api.post('/api/orders/place_order/', orderData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
            });

            if (orderResponse.status === 201) {
                const { message, partial_order, waiting_list_order } = orderResponse.data;

                let orderMessage;

                if (partial_order && waiting_list_order) {
                    orderMessage = `${message} Your reserved shares: ${partial_order.equivalent_number_of_shares}, ` +
                    `waiting list shares: ${waiting_list_order.equivalent_number_of_shares}.`;
                } else {
                    orderMessage = orderResponse.data.message;
                }

                const orderId = orderResponse.data.order.id;

                await signTermSheet(orderId);

                dispatch({
                    type: PLACE_ORDER_SUCCESS,
                    payload: {
                        message: orderMessage,
                        orderId: orderResponse.data.order ? orderResponse.data.order.id : null,
                        tabValue: (parseInt(tabValue) + 1).toString(), // Move to next tab
                    },
                });
            } else {
                throw new Error('Something went wrong, cannot proceed');
            }
        } catch (err) {
            dispatch({
                type: PLACE_ORDER_FAILURE,
                payload: err.message || 'An error occurred while placing the order.',
            });
        }
    };
};
export const handleWaitingListSignUp = (dealId, formData) => {
    return async (dispatch) => {
      dispatch({ type: PLACE_WAITING_LIST_ORDER_REQUEST });  // Start the request
  
      const orderData = {
        deal: dealId,
        reservation_amount: formData.reservation_amount,
        equivalent_number_of_shares: formData.equivalent_number_of_shares,
        final_reservation_amount: formData.final_reservation_amount,
        status: 2, // Status 2 for waiting list
      };
  
      try {
        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
        };
  
        const orderResponse = await api.post('/api/orders/place_order/', orderData, config);
  
        if (orderResponse.status === 201) {
          const { message, order } = orderResponse.data;
          dispatch({
            type: PLACE_WAITING_LIST_ORDER_SUCCESS,
            payload: {
              message,
              orderId: order.id,
   },
          });
        } else {
          throw new Error('Failed to place order in the backend');
        }
      } catch (error) {
        dispatch({
          type: PLACE_WAITING_LIST_ORDER_FAILURE,
          payload: error.message || 'An error occurred while placing the waiting list order.',
        });
      }
    };
  };

// Function to generate the signed document
const signTermSheet = async (orderId) => {
    try {
        const response = await api.post('/api/documents/generate_doc/', {
            order: orderId,
            document_type: '4', // Term Sheet
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
        });

        if (response.status === 201) {
            console.log('Signed document generated successfully:', response.data);
        } else {
            console.error('Failed to generate signed document:', response.status, response.data);
        }
    } catch (error) {
        console.error('Error generating signed document:', error);
    }
};

// Action to set tab value
export const setTabValue = (tabValue) => ({
    type: SET_TAB_VALUE,
    payload: tabValue,
});
