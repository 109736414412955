import { 
    UPDATE_ORDER_DATA, 
    RESET_ORDER_DATA, 
    PLACE_ORDER_FAILURE, 
    PLACE_ORDER_REQUEST, 
    PLACE_ORDER_SUCCESS, 
    SET_TAB_VALUE, 
    SET_ORDER_ERROR, 
    CLEAR_ORDER_ERROR, 
    SET_FORM_VALIDITY, 
    UPDATE_DEAL_DATA, 
    UPDATE_COMPANY_DATA,
    PLACE_WAITING_LIST_ORDER_REQUEST,
    PLACE_WAITING_LIST_ORDER_SUCCESS,
    PLACE_WAITING_LIST_ORDER_FAILURE,
    UPDATE_WAITING_LIST_DATA
} from "../actions/types";

const initialOrderData = {
    reservation_amount: 0,
    share_price: 0,
    equivalent_number_of_shares: 0,
    final_reservation_amount: 0,
    term_sheet_acceptance_1: false,
    term_sheet_acceptance_2: false,
    term_sheet_acceptance_3: false,
    term_sheet_acceptance_4: false,
};

const initialOrderState = {
    loading: false,
    orderMessage: null,
    orderId: null,
    tabValue: '1',
    error: null,
    formData: initialOrderData,
    isValueValid: true,  // Track form validity
    dealData: null,      // Track deal data
    companyData: null,   // Track company data
    waitingListOrder: null, // New state to hold waiting list order details
    waitingListError: null, // New state to hold waiting list order errors
    waitingListData: null,  // Add waiting list data state
};

const orderReducer = (state = initialOrderState, action) => {
    switch (action.type) {
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.payload,
            };
        case UPDATE_ORDER_DATA:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    ...action.payload
                }
            };
        case RESET_ORDER_DATA:
            return {
                ...state,
                formData: initialOrderData
            };
        case PLACE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case PLACE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                orderMessage: action.payload.message,
                orderId: action.payload.orderId,
                tabValue: action.payload.tabValue,
                error: null,
            };
        case PLACE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_ORDER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case CLEAR_ORDER_ERROR:
            return {
                ...state,
                error: null, // Reset error to null instead of an empty string
            };
        case SET_FORM_VALIDITY:
            return {
                ...state,
                isValueValid: action.payload,  // Update form validity
            };
        case UPDATE_DEAL_DATA:
            return {
                ...state,
                dealData: {
                    ...state.dealData,
                    ...action.payload, // Merge the deal data into the state
                }
            };
        case UPDATE_WAITING_LIST_DATA:
                return {
                  ...state,
                  waitingListData: action.payload, // Store waiting list data in state
                };
        case UPDATE_COMPANY_DATA:
            return {
                ...state,
                companyData: {
                    ...state.companyData,
                    ...action.payload, // Merge the company data into the state
                }
            };
        
        // Handle Waiting List Order Requests
        case PLACE_WAITING_LIST_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                waitingListError: null,
            };

        case PLACE_WAITING_LIST_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                waitingListOrder: action.payload, // Save the waiting list order data
                waitingListError: null,
            };

        case PLACE_WAITING_LIST_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                waitingListError: action.payload, // Store error message for waiting list order
            };

        default:
            return state;
    }
};

export default orderReducer;
