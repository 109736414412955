import React, { useEffect, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Loading from '../general/Loading';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrderData, setValueValidity } from '../../actions/order';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { alpha } from '@mui/material/styles';

// Utility function to calculate rounded values based on the share price
const calculateAdjacentShares = (baseAmount, sharePrice, minimumInvestmentSize) => {
  if (!baseAmount || !sharePrice || !minimumInvestmentSize) {
    return {
      bottomShareCount: 0,
      topShareCount: 0
    };
  }
  
  const bottomCount = Math.max(Number(baseAmount) - Number(sharePrice), Number(minimumInvestmentSize));
  const topCount = Number(baseAmount) + Number(sharePrice);
  
  return {
    bottomShareCount: Number.isFinite(bottomCount) ? bottomCount : 0,
    topShareCount: Number.isFinite(topCount) ? topCount : 0
  };
};

const calculateRoundedValues = (reservationAmount, sharePrice) => {
  if (!reservationAmount || !sharePrice) {
    return {
      roundedReservationAmount: 0,
      calculatedShares: 0,
      finalReservationAmount: 0
    };
  }

  const amount = Number(reservationAmount);
  const price = Number(sharePrice);
  
  if (!Number.isFinite(amount) || !Number.isFinite(price)) {
    return {
      roundedReservationAmount: 0,
      calculatedShares: 0,
      finalReservationAmount: 0
    };
  }

  const roundedReservationAmount = Math.ceil(amount / price) * price;
  const calculatedShares = Math.round(roundedReservationAmount / price);
  const finalReservationAmount = roundedReservationAmount * 0.95;

  return {
    roundedReservationAmount,
    calculatedShares,
    finalReservationAmount
  };
};

const ReservationDetails = () => {
  const dispatch = useDispatch();

  // Access state from Redux store
  const formData = useSelector((state) => state.order.formData);
  const error = useSelector((state) => state.order.error);
  const deal = useSelector((state) => state.order.dealData);
  const company = useSelector((state) => state.order.companyData);
  const [helperText, setHelperText] = useState('');
  const [inputReservationAmount, setInputReservationAmount] = useState(formData?.reservation_amount || 0);

  const debounceTimerRef = useRef(null);
  const [isChecking, setIsChecking] = useState(false);
  const [bottomShareCount, setBottomShareCount] = useState(0);
  const [topShareCount, setTopShareCount] = useState(0);

  useEffect(() => {
    if (deal) {
      const { roundedReservationAmount, calculatedShares, finalReservationAmount } = calculateRoundedValues(
        deal.minimum_investment_size,
        deal.implied_share_price
      );

      const initialFormData = {
        reservation_amount: roundedReservationAmount,
        share_price: deal.implied_share_price,
        equivalent_number_of_shares: calculatedShares,
        final_reservation_amount: finalReservationAmount,
      };

      setInputReservationAmount(roundedReservationAmount);
      dispatch(updateOrderData(initialFormData, deal.available_shares));

      const adjacentShares = calculateAdjacentShares(
        roundedReservationAmount,
        deal.implied_share_price,
        deal.minimum_investment_size
      );
      setBottomShareCount(adjacentShares.bottomShareCount);
      setTopShareCount(adjacentShares.topShareCount);
    }
  }, [deal, dispatch]);

  const handleDebouncedCalculation = (value) => {
    const numValue = Number(value);
    if (!Number.isFinite(numValue)) {
      setHelperText('Please enter a valid number');
      return;
    }

    const { roundedReservationAmount, calculatedShares, finalReservationAmount } = calculateRoundedValues(
      numValue,
      formData.share_price
    );

    const updatedFormData = {
      ...formData,
      reservation_amount: roundedReservationAmount,
      equivalent_number_of_shares: calculatedShares,
      final_reservation_amount: finalReservationAmount,
    };

    let valueIsValid = true;

    if (roundedReservationAmount < deal.minimum_investment_size) {
      setHelperText('Reservation amount is smaller than the minimum investment.');
      valueIsValid = false;
    } else if (calculatedShares > deal.available_shares) {
      setHelperText('Not enough available shares. Excess will be placed on waiting list.');
      valueIsValid = true;
    } else {
      setHelperText(`Adjusted to ${roundedReservationAmount.toFixed(2)} for minimum whole share requirement.`);
    }

    dispatch(updateOrderData(updatedFormData));
    dispatch(setValueValidity(valueIsValid));
    setInputReservationAmount(roundedReservationAmount);

    const adjacentShares = calculateAdjacentShares(
      roundedReservationAmount,
      formData.share_price,
      deal.minimum_investment_size
    );
    setBottomShareCount(adjacentShares.bottomShareCount);
    setTopShareCount(adjacentShares.topShareCount);

    setIsChecking(false);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setInputReservationAmount(value);
    setIsChecking(true);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => handleDebouncedCalculation(value), 500);
  };

  const handleShareCountButtonClick = (newAmount) => {
    if (Number.isFinite(newAmount)) {
      setInputReservationAmount(newAmount);
      handleDebouncedCalculation(newAmount);
    }
  };

  if (!deal) {
    return <Loading />;
  }
  const renderTextField = (label, name, value, disabled = false, helperText = '') => (
    <Grid item xs={12} lg={12}>
      <TextField
        required
        label={label}
        name={name}
        value={value || ''}
        disabled={disabled}
        onChange={disabled ? undefined : handleChange}
        fullWidth
        helperText={disabled ? null : helperText || error}
        InputProps={{
          endAdornment: !disabled && name === 'reservation_amount' ? (
            <InputAdornment position="end">
              <Box sx={{ display: 'flex', gap: 1 }}>
                {Number.isFinite(bottomShareCount) && bottomShareCount >= deal.minimum_investment_size && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleShareCountButtonClick(bottomShareCount)}
                    startIcon={<KeyboardDoubleArrowDownIcon />}
                    sx={{
                      borderRadius: '16px',
                      color: 'success.main',
                      borderColor: 'success.main',
                      minWidth: '100px',
                      '&:hover': {
                        backgroundColor: alpha('#4caf50', 0.1),
                        borderColor: 'success.main',
                      },
                    }}
                  >
                    {bottomShareCount.toFixed(2)}
                  </Button>
                )}
                {Number.isFinite(topShareCount) && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleShareCountButtonClick(topShareCount)}
                    startIcon={<KeyboardDoubleArrowUpIcon />}
                    sx={{
                      borderRadius: '16px',
                      color: 'primary.main',
                      minWidth: '100px',
                      '&:hover': {
                        backgroundColor: alpha('#1976d2', 0.1),
                      },
                    }}
                  >
                    {topShareCount.toFixed(2)}
                  </Button>
                )}
              </Box>
            </InputAdornment>
          ) : null,
        }}
      />
    </Grid>
  );
  
  return (
    <Box sx={{ padding: '20px' }} direction={'row'}>
      <Grid container spacing={2}>
        {/* Company Information */}
        {company && (
          <Grid item xs={12} lg={3}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '50px', alignItems: 'center', justifyContent: 'center', height: '10vh' }}>
              <img alt="Company Logo" src={company.logo} width="50vh" />
              <Typography variant="h4" marginTop="30px">{company.name}</Typography>
            </Paper>
          </Grid>
        )}

        {/* Reservation Form */}
        <Grid item xs={12} lg={9}>
          <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            {formData && (
              <>
                {renderTextField('Reservation amount', 'reservation_amount', inputReservationAmount, false, helperText)}
                {renderTextField('Share Price (excl. Sales Fee)', 'share_price', formData.share_price, true)}
                {renderTextField('Share amount', 'share_amount', formData.equivalent_number_of_shares, true)}
                {renderTextField('Reserved Value of Stocks', 'final_reservation_amount', formData.final_reservation_amount, true)}
              </>
            )}
          </Grid>
        </Grid>

        {/* Deal Information */}
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontSize: '0.8em' }}>
            EquitiBridge does not guarantee the availability of Deals. Minimum investment: {deal.minimum_investment_size} GBP.
            Reservation Amounts below this minimum are adjusted.
          </Typography>
        </Grid>

        {/* Info Alert */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Alert severity="info" sx={{ fontSize: '0.8rem', marginTop: '20px' }}>
            Reservations are based on commitment order. Once capacity is reached, reservations go on the waitlist.
          </Alert>
        </Box>
      </Grid>
    </Box>
  );
};

export default ReservationDetails;