import React, {useState, useEffect} from "react";
import api from '../../axiosInstance';


const CSRFToken = () => {


    const [csrftoken, setcsrftoken] = useState('');

    const getCookie = ( name ) => {
        
        let cookieValue = null;
        console.log("All cookies:", document.cookie);
        if (document.cookie && document.cookie !== '') {
            
            let cookies = document.cookie.split(';');
            
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();


                if (cookie.substring(0,name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the CSRF token by making a request to your backend
                await api.get('/api/accounts/csrf_cookie');
                
                // Set the CSRF token after fetching it
                const csrfToken = getCookie('csrftoken');
                setcsrftoken(csrfToken);
                
                // Set the CSRF token in Axios defaults
                api.defaults.headers.common['X-CSRFToken'] = csrfToken;

            } catch (err) {
                console.error('Error fetching CSRF token:', err);
            }
        };
        fetchData();
    }, []);

    return (
       <input type='hidden' name='csrfmiddlewaretoken' value={csrftoken} />
    )
};

export default CSRFToken;